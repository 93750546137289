.whitelabel-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.whitelabel-title {
  color: #343a40;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 15px;
}

.whitelabel-create-btn {
  background-color: #28a745;
  color: white;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.whitelabel-create-btn:hover {
  background-color: #218838;
}

.whitelabel-alert {
  font-size: 18px;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.whitelabel-table {
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-collapse: collapse;
}

.whitelabel-table th, .whitelabel-table td {
  padding: 14px 18px;
  text-align: left;
}

.whitelabel-table th {
  background-color: #343a40;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
}

.whitelabel-table td {
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  vertical-align: middle;
}

.whitelabel-table tr:not(:last-child) td {
  border-bottom: 2px solid #e9ecef;
}

.whitelabel-status {
  background-color: #e9f7ef;
  color: #155724;
  padding: 6px 12px;
  border-radius: 20px;
  font-weight: bold;
  display: inline-block;
  font-size: 14px;
}

.whitelabel-edit-btn {
  background-color: #ffc107;
  color: black;
  padding: 8px 12px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
}

.whitelabel-edit-btn:hover {
  background-color: #e0a800;
}

.whitelabel-delete-btn {
  background-color: #dc3545;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  margin-left: 5px;
}

.whitelabel-delete-btn:hover {
  background-color: #c82333;
}

.whitelabel-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.whitelabel-pagination-btn {
  background-color: #6c757d;
  color: white;
  padding: 6px 15px;
  border-radius: 5px;
  font-weight: bold;
  margin: 0 5px;
}

.whitelabel-pagination-btn:hover {
  background-color: #5a6268;
}

.whitelabel-page-info {
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
}

.whitelabel-datetime {
  font-size: 14px;
  color: #6c757d;
  font-weight: bold;
  white-space: nowrap;
}

.whitelabel-table-wrapper {
  background: #fff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.whitelabel-table th, .whitelabel-table td {
  padding: 12px 15px;
}

.whitelabel-table tr:hover {
  background-color: #f1f1f1;
}
