.customer-details-container {
  margin: 20px auto;
  width: 80%;
  font-family: Arial, sans-serif;
}

.customer-header {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #007bff;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.customer-header img {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.customer-info h2 {
  font-size: 26px;
  color: #333;
}

.customer-info p {
  font-size: 14px;
  color: #666;
}

.customer-stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 20px;
}

.customer-stats div {
  flex: 1;
  min-width: 150px;
  text-align: center;
  padding: 15px;
  background: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.customer-stats h3 {
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
}

.customer-timeline {
  margin-top: 20px;
}

.timeline {
  position: relative;
  padding-left: 30px;
  border-left: 3px solid #007bff;
}

.timeline-item {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
}

.timeline-icon {
  position: relative;
  margin-right: 20px;
}

.timeline-icon img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
  border: 2px solid #007bff;
}

.timeline-content {
  background-color: #f4f8fb;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.timeline-content h4 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #007bff;
}

.timeline-content p {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
}

.additional-details {
  margin-top: 30px;
}

.additional-details h3 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #007bff;
}

.additional-details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.additional-details-item {
  padding: 15px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #333;
}
