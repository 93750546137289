.full-height {
  height: 100vh;
}

.canvasjs-chart-credit {
  display: none;
}

.files-ui-file-item-status-container.file-status-ok {
  display: none;
}

#scheduleCampaign .react-datepicker-wrapper {
  width: 100%;
}

input:focus-visible {
  outline: 1px solid transparent;
}
.esdev-app .sripo-watermark{
  display: none !important;
}
.collapse{
  visibility: visible !important;
}
/* REACT TAGS */

.customTag {
  background: var(--chakra-colors-primary-main);
  border-radius: 5px;
  color: var(--chakra-colors-white);
  padding: .2rem;
}

.inputTag {
  width: 100%;
  height: var(--input-height);
  font-size: var(--input-font-size);
  -webkit-padding-start: var(--input-padding);
  padding-inline-start: var(--input-padding);
  -webkit-padding-end: var(--input-padding);
  padding-inline-end: var(--input-padding);
  border-radius: var(--input-border-radius);
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  --input-font-size: var(--chakra-fontSizes-md);
  --input-padding: var(--chakra-space-4);
  --input-border-radius: var(--chakra-radii-md);
  --input-height: var(--chakra-sizes-10);
  border: 1px solid;
  border-color: inherit;
  background: inherit;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-top: var(--chakra-space-1);

}

.inputTag:hover {
  border-color: var(--chakra-colors-gray-300);
}

.tagsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
}

* {
  box-sizing: border-box;
}

/* SWEET ALET Z-INDEX */

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 1900 !important;
}

.swal2-container.swal2-center.swal2-backdrop-show {
  display: flex;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: var(--chakra-zIndices-modal);
  -moz-box-pack: center;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  overscroll-behavior-y: none;
  width: 100vw;
  height: var(--chakra-vh);
}

.swal2-popup.swal2-modal.swal2-show {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  outline: transparent solid 2px;
  color: inherit;
  z-index: var(--chakra-zIndices-modal);
  --modal-bg: var(--chakra-colors-white);
  --modal-shadow: var(--chakra-shadows-lg);
  background: var(--modal-bg);
  box-shadow: var(--modal-shadow);
  max-width: var(--chakra-sizes-md);
  outline-offset: 2px;
  border-radius: var(--chakra-radii-md);
  margin-top: var(--chakra-space-16);
  margin-bottom: var(--chakra-space-16);
  padding: var(--chakra-space-4) var(--chakra-space-6);
}

.swal2-actions {
  display: flex !important;
  justify-content: flex-start !important;
  width: 100% !important;
  flex-direction: row-reverse!important;

}

.swal2-styled {
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  /*! display:inline-flex; */
  -webkit-appearance: none;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  appearance: none !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  position: relative !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  line-height: 1.2 !important;
  border-radius: 2px !important;
  font-weight: 400 !important;
  transition-property: var(--chakra-transition-property-common) !important;
  transition-duration: var(--chakra-transition-duration-normal) !important;
  height: var(--chakra-sizes-8) !important;
  min-width: var(--chakra-sizes-8) !important;
  font-size: var(--chakra-fontSizes-sm) !important;
  -webkit-padding-start: var(--chakra-space-3) !important;
  padding-inline-start: var(--chakra-space-3) !important;
  -webkit-padding-end: var(--chakra-space-3) !important;
  padding-inline-end: var(--chakra-space-3) !important;

  
  margin-right: var(--chakra-space-2) !important;
  border-radius: 2px !important;
}

.swal2-cancel {
  color: var(--chakra-colors-danger) !important;
  border: 1px solid !important;
  border-color: var(--chakra-colors-danger) !important;
  background: transparent!important;
}
.swal2-confirm{
  color: var(--chakra-colors-white)!important;
  background: var(--chakra-colors-primary-main)!important;
}
.swal2-html-container{
  margin: 0!important;text-align: unset!important;
}
.swal2-html-container > div:first-child{
  display: flex;flex-direction: column;gap: var(--chakra-space-5);
}
div:where(.swal2-container) div:where(.swal2-loader){
  /* display: inline-block!important; */
  border-top-color: var(--chakra-colors-primary-main)!important;
  border-right-color: var(--chakra-colors-primary-main)!important;
  margin: 0 var(--chakra-space-4)!important;
  border-style: solid!important;
  border-radius: 99999px!important;
  border-width: 2px!important;
  border-bottom-color: var(--chakra-colors-transparent)!important;
  border-left-color: var(--chakra-colors-transparent)!important;
  animation: swal2-rotate-loading .5s linear 0s infinite normal!important;
  width: var(--spinner-size)!important;
  height: var(--spinner-size)!important;
  --spinner-size: var(--chakra-sizes-6)!important;
}

.joint-halo.joint-theme-modern > label.box{
  display: none!important;
}
.rti--container{
  width: 100%;
}
.flatpickr-calendar{
  top: 20vw !important;
}
.profile-card {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #1877F2;
  object-fit: cover;
  margin-bottom: 15px;
}

.user-name {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

.logout-btn {
  background: linear-gradient(135deg, #1877F2, #ff4b2b);
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 80%;
  text-align: center;
}

.logout-btn:hover {
  background: linear-gradient(135deg, #ff4b2b, #1877F2);
  transform: scale(1.05);
}

.logout-btn svg {
  width: 18px;
  height: 18px;
  fill: white;
}