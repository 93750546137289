/* Block Overview Container */
.block-overview-container {
  padding: 16px;
}

/* No Blocks Styling */
.no-blocks-container {
  text-align: center;
  margin-top: 50px;
}

.no-blocks-container h1 {
  font-size: 24px;
  font-weight: bold;
}

.no-blocks-container h3 {
  font-size: 18px;
  margin: 10px 0;
}

.no-blocks-container .create-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.no-blocks-container .create-button:hover {
  background-color: #0056b3;
}

.no-blocks-container .no-blocks-image {
  margin-top: 20px;
  max-width: 200px;
}

/* Header Styling */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 24px;
  font-weight: bold;
}

.primary-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.primary-button:hover {
  background-color: #0056b3;
}

/* Block Cards Styling */
.blocks-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.block-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: calc(33.333% - 20px);
  box-sizing: border-box;
  text-align: center;
  position: relative;
  border: 1px solid #ddd;
  margin-bottom: 16px;
}

.block-image-container {
  position: relative;
}

.block-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.block-updated {
  position: absolute;
  top: 8px;
  right: 8px;
  background: rgba(255, 255, 255, 0.8);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: #6c757d;
  text-align: right;
  white-space: nowrap;
}

/* Block Title */
.block-title {
  font-size: 18px;
  font-weight: bold;
  margin: 8px 0;
}

/* Block Author Styling */
.block-author {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.block-author .author-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.block-author .delete-button {
  margin-left: auto;
  background: none;
  border: none;
  color: #dc3545;
  font-size: 18px;
  cursor: pointer;
}

.block-author .delete-button:hover {
  color: #b02a37;
}

/* Block Actions */
.block-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.block-actions .edit-link,
.block-actions .preview-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}

.block-actions .edit-link:hover,
.block-actions .preview-button:hover {
  background-color: #0056b3;
}

/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  position: relative;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-image {
  max-width: 100%;
  border-radius: 5px;
  margin-top: 10px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background-color: #f9f9f9; /* Matches your theme */
}

.custom-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader-circle {
  border: 4px solid #e0e0e0; /* Light gray border */
  border-top: 4px solid #4fa94d; /* Your theme's primary color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container p {
  font-size: 16px;
  color: #333; /* Neutral text color */
  margin-top: 10px;
}
