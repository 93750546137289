.whitelabel-edit-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
}

.whitelabel-edit-title {
  color: #343a40;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.whitelabel-edit-form {
  display: flex;
  flex-direction: column;
}

.whitelabel-edit-form label {
  font-weight: bold;
  margin-top: 10px;
}

.whitelabel-edit-form input,
.whitelabel-edit-form select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.whitelabel-edit-form input:disabled {
  background-color: #e9ecef;
}

.whitelabel-edit-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.whitelabel-edit-actions .btn {
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
}

.whitelabel-edit-actions .btn-success {
  background-color: #28a745;
  color: white;
}

.whitelabel-edit-actions .btn-success:hover {
  background-color: #218838;
}

.whitelabel-edit-actions .btn-secondary {
  background-color: #6c757d;
  color: white;
}

.whitelabel-edit-actions .btn-secondary:hover {
  background-color: #5a6268;
}